document.addEventListener('DOMContentLoaded', function(event) {
  var video = document.getElementById('video');
  var scrim = document.getElementById('play-scrim');
  var playerOrigin = '*';

  function startVideo() {

    // Show modal
    scrim.className += ' hidden';

    // Play video
    video.contentWindow.postMessage({
      method: 'play',
      value: null,
    }, playerOrigin);
  }

  scrim.addEventListener('click', startVideo);

  document.documentElement.addEventListener('keyup', function(e) {
    if (e.keyCode == 27) { // escape key
      closeModal();
    }
  });
});
